@import '../../styles/library.scss';

.footer {
    grid-row: 4;
    grid-column: 1 / -1;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 75px;

    color: $theme-secondary;
    font-size: 14px;

    &.mobile
    {
        font-size: 11px;
        padding: 15px 20px;
    }
}

.ft-cp {
    grid-row: 2;
    grid-column: 1;
}

.ft-subtext
{
    color: $theme-white;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.ft-contact {
    grid-row: 2;
    grid-column: 2;
    text-align: right;
}

.email-link
{
    text-decoration: none;
    color: $theme-white;
}

.email-link:hover
{
    text-decoration: underline;
}