@import '../../styles/library.scss';

.home-page-layout
{
    grid-row: 2;
    grid-column: 1 / -1;

    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-auto-rows: min-content;

    margin: 15px 0 0 0;
    //border-bottom: 1px solid $lite-shade-col;
    height: auto;
    text-align: center;

    &.mobile
    {
      grid-template-columns: 1fr;
      padding: 0 20px;
      display: inline-block;
    }
}

.home-splash 
{
    overflow: hidden;
    grid-column: 1/-1;
    background-color: $theme-black;
    //background: url('../../img/plane_bg.JPG') no-repeat;
    background-clip: content-box;
    background-position-x: 0px;
    background-position-y: -40px;
    background-size: 100% auto;
    margin: 0 0 50px 0;
    border-radius: 36px;

    animation: slideIn 0.65s forwards;
    animation-delay: 2s;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 500px;
    align-items: center;

    &.mobile
    {
        background-position-x: -1170px;
        background-position-y: -209px;
        background-size: auto;
    }
}

.home-title-words
{
    grid-row: 1;
    grid-column: 1;
    margin: -10px 0 0 75px;
    color: $theme-lite;
    font-size: 100px;
    letter-spacing: 2px;
    line-height: 1.1em;
    font-weight: 900;
    text-align: left;
    margin-right: 25px;

    &.mobile
    {
        grid-column: 1/-1;
        margin: -10px auto;
        font-size: 8vw;
        text-align: center;
    }
}

.home-resume-btn-cont {
    padding: 0px 25px;

    .home-resume-btn {
        text-decoration: none;
        color: $theme-white;
        font-size: 20px;
        font-weight: 600;
        margin: 0px auto 50px;
        padding: 15px 20px;
        border-radius: 20px;
        background-color: $theme-primary;
        
        &:hover {
            background-color: $theme-secondary;
        }
    }
}


.home-page-layout-new
{
    grid-row: 2;
    grid-column: 1 / -1;

    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;

    margin: 15px 0 0 0;
    //border-bottom: 1px solid $lite-shade-col;
    height: auto;
    text-align: center;

    &.mobile
    {
      grid-template-columns: 1fr;
      padding: 0 10px;
      display: inline-block;
    }
}


.home-title-words-new
{
    grid-row: 1;
    grid-column: 1/-1;
    margin: -10px 0 0 20px;
    color: $theme-lite;
    font-size: 3vw;
    letter-spacing: 2px;
    line-height: 1.1em;
    font-weight: 900;
    text-align: center;
    margin-right: 25px;

    &.mobile
    {
        font-size: 7vw;
    }
}

.home-col-new {
    padding: 50px 0 0 0;

    .desc {
        margin: 0 auto 25px;
        padding: 0 0 25px;
        font-size: 20px;

        &.mobile {
            font-size: 18px;
        }
    }

    .btn-cont {
        white-space: nowrap;
        margin: 25px auto;
        text-align: center;

        .btn {
            display: inline-grid;
            grid-template-columns: 20px min-content;
            align-items: center;
            cursor: pointer;

            padding: 15px;
            border-radius: 12px;
    
            text-decoration: none;
            color: $theme-white;
            font-weight: 700;
            background-color: $theme-primary;

            transition-duration: 0.2s;

            &.mobile {
                font-size: 13px;
            }

            .content {
                padding: 0 5px 0 10px;
            }

            &.alt {
                background-color: $deegee-gr-drk;

                &:hover {
                    padding: 15px 20px;
                    background-color: $deegee-gr-lte;
                }
            }

            &:hover {
                padding: 15px 20px;
                background-color: $theme-secondary;
            }
        }
    }
}

.github-logo {
    background-image: url("../../img/icons/github.png");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}

.writing-logo {
    background-image: url("../../img/icons/writing.png");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}

.project-logo {
    background-image: url("../../img/icons/settings.png");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}