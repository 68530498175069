@import '../../styles/library';

body
{
  background-color: $theme-black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  font-family: $mont-font;
  min-height: 100vh;
  flex-direction: column;
  //align-items: center;
  font-size: 24px;
  color: $lite-shade-col;

  margin: 0 auto;
  max-width: 1200px;

  display: grid;
  grid-template-rows: 50px auto 200px auto;
  grid-template-columns: 1fr 1fr 1fr;
  padding-left: calc(100vw - 100%);
  overflow-x: hidden;

  cursor: default;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;

  &.Dee-Dee
  {
    background-color: #151515;
    grid-template-rows: auto;
    grid-template-columns: auto;

    &.mobile
    {
      padding: 0 0 0 0;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
    }
  }

  &.mobile
  {
    padding: 0 0 0 0;
    //width: 100vw;
    display: flex;
  }
}

.page-title
{
  grid-column: 1;
  grid-row: 1;
  font-size: 20px;
  font-weight: lighter;
  color: rgba($lite-shade-col,0.75);

  &.mobile
  {
    display: inline-block;
    background-color: $dark-shade-col;
    text-align: center;
  }
}

.page-container
{
    grid-row: 2;
    grid-column: 1 / -1;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: min-content;

    margin: 15px 0 0 0;
    border-bottom: 1px solid $lite-shade-col;
    height: auto;
    text-align: center;

    &.mobile
    {
      grid-template-columns: 1fr;
      padding: 0 20px;
      display: inline-block;
    }
}

.preload-img
{
  display: none;
}

// STYLES FOR LOADING SPINNERS
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
}
.lds-ellipsis div {
  position: absolute;
  top: 4px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $lite-accnt-col;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}