@import '../../styles/library.scss';

.error-title-words
{
    grid-row: 1;
    grid-column: 2;
    margin: 50px 0 0 0;
    color: $lite-white-col;
    font-size: 75px;
    letter-spacing: 2px;
    line-height: 1em;
    font-weight: 700;
    font-variant: small-caps;
    text-align: right;
    margin-right: 25px;
}