/*

This file contains all of the standard style library

*/

@import './colors.scss';
@import './scrollbar.scss';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
$mont-font: 'Montserrat', sans-serif;
$theme-font: 'Montserrat', sans-serif;
$rale-font: 'Raleway', sans-serif;


// main site colors
$dark-black-col: #121212;
$pale-blue-accent: #9EADC8;
$pale-red-accent: #ED6A5A;
$dark-shade-col: #2A2B2E;
$dark-accen-col: #FFA630;
$main-brand-col: #4d5278;
$lite-accnt-col: #A4C2A8;
$lite-shade-col: #FFFFEA;
$lite-white-col: #ffffff;

$bg-angle: 160deg;

// dee dee colors;

$deedee-bg-col: #151515;
$deegee-gr-drk: #8da690;
$deegee-gr-lte: #a4c2a8;
$deedee-white: #ffffff;

// the :export directive is the magic sauce for webpack
:export {
    darkShade: $dark-shade-col;
    darkAccent: $dark-accen-col;
    mainBrand: $main-brand-col;
    liteAccent: $lite-accnt-col;
    liteShade: $lite-shade-col;
    paleBlue: $pale-blue-accent;
    paleRed: $pale-red-accent;
  }
  
